import axios from 'axios';

let api = '/api'
// if (process.env.NODE_ENV === 'production') {
//     api = 'http://api.tounali.com/api'
// }

const service = axios.create({
    baseURL: api,
    withCredentials: true
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        console.log(error); // for debug
    }
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        if (res.code != 1) {
            if (res.code === 403 || res.code === 401) {
            }
        } else {
            return res;
        }
    },
    (error) => {
        console.log('err' + error);;
    }
);

export default service;
