import { createRouter, createWebHistory } from 'vue-router';
import bus from '@/bus';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
const routes = [
    {
        path: '/',
        name: 'Ccfsf',
        component: () => import('@/views/ccfsf'),
        children: [
            // 征文启示
            {
                path: '',
                name: 'SolicitArticles',
                component: () => import('@/views/ccfsf/SolicitArticles.vue')
            },
            // 组委与评委
            {
                path: 'committee',
                name: 'Judges',
                component: () => import('@/views/ccfsf/Judges.vue')
            },
            // 会议议程
            {
                path: 'agenda',
                name: 'Meeting',
                component: () => import('@/views/ccfsf/Meeting.vue')
            },
            // 主题演讲
            {
                path: 'speech',
                name: 'Speech',
                component: () => import('@/views/ccfsf/Speech.vue')
            },
            // 平行论坛
            {
                path: 'parallel',
                name: 'Forum',
                component: () => import('@/views/ccfsf/Forum.vue')
            },
            // 获奖论文
            {
                path: 'honors',
                name: 'Paper',
                component: () => import('@/views/ccfsf/WinningPaper.vue')
            },
            // 期刊支持
            {
                path: 'journals',
                name: 'Periodical',
                component: () => import('@/views/ccfsf/Periodical.vue')
            },
            // 坛光掠影
            {
                path: 'photos',
                name: 'Lesson',
                component: () => import('@/views/ccfsf/Lesson.vue')
            },
            // 下载专区
            {
                path: 'download',
                name: 'Download',
                component: () => import('@/views/ccfsf/Download.vue')
            },
            // 论坛综述
            {
                path: 'summary',
                name: 'Summarize',
                component: () => import('@/views/ccfsf/Summarize.vue')
            },
            {
                // 关于论坛
                path: 'aboutForum',
                name: 'AboutForum',
                component: () => import('@/views/ccfsf/AboutForum.vue')
            },
            {
                // 理事会
                path: 'council',
                name: 'Council',
                component: () => import('@/views/ccfsf/Council.vue')
            },
            {
                // 理事会
                path: 'support',
                name: 'Support',
                component: () => import('@/views/ccfsf/Support.vue')
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// 跳转后自动返回页面顶部
router.afterEach(() => {
    NProgress.done();
});

router.beforeEach(async (to, from, next) => {
    bus.emit(to.fullPath);
    NProgress.start();
    next();
});

export default router;
