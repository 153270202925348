import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { defineAsyncComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
// 加载组件

export default {
  __name: 'App',
  setup(__props) {
    const Loading = defineAsyncComponent(() => import('@/components/loading'));
    // 加载异常组件
    const Error = defineAsyncComponent(() => import('@/components/error'));
    const store = useStore();
    const Footer = defineAsyncComponent({
      // 加载函数
      loader: () => import('@/components/footer'),
      // 加载异步组件时使用的组件
      loadingComponent: Loading,
      // 展示加载组件前的延迟时间，默认为 200ms
      delay: 200,
      // 加载失败后展示的组件
      errorComponent: Error,
      // 也会显示这里配置的报错组件，默认值是：Infinity
      timeout: 1000
    });
    const Header = defineAsyncComponent({
      // 加载函数
      loader: () => import('@/components/header'),
      // 加载异步组件时使用的组件
      loadingComponent: Loading,
      // 展示加载组件前的延迟时间，默认为 200ms
      delay: 200,
      // 加载失败后展示的组件
      errorComponent: Error,
      // 也会显示这里配置的报错组件，默认值是：Infinity
      timeout: 1000
    });
    onMounted(async () => {
      if (store.getters.year.id == '') {
        // await store.dispatch('getYear');
      }
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_unref(Header)), _createVNode(_component_router_view), _createVNode(_unref(Footer))], 64);
    };
  }
};